@import "tailwindcss/base";
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
    --grid-inset: transparent;
}

@layer base {
    /* 
    Fonts
    Ideal Sans: 200, 300, 300i, 400, 400i
    Ideal Sans SC: 200,300
    Ideal Sans Nums: 300
    Quarto: 300, 300i
     */

    body { 
        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
        text-rendering: optimizeLegibility;
    }

    img {
        image-rendering: crisp-edges;
    }
    img.soft {
        image-rendering: auto;
    }

    a { transition: color .1s; }

    h1,h2,h3,h4 { 
        line-height: 1.1; 
    }

    table {
        width: 100%;
        line-height: 1.6em;
    }

    th { 
        @apply uppercase font-light text-xs tracking-wide; 
    }

    strong { font-weight: 400; }

    .visually-hidden { 
        position: absolute !important;
        height: 1px; 
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap; /* added line */
    }

    .video-hd-wrapper {
        position: relative;
        padding-bottom: 56.25%; 
    }
    .video-sq-wrapper {
        position: relative;
        padding-bottom: 100%; 
    }

    /* controls */

    .controls[data-state=hidden] {
        display: none;
    }
    .controls[data-state=visible] {
        display: block;
    }
    .controls .progress {
        cursor: pointer;
    }
    .controls progress {
        display: block;
        width: 100%;
        height: 4px;
        margin-top: 0.125rem;
        border: none;
        overflow: hidden;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -webkit-appearance: none;
        border-radius: 0;
        color: rgba(255,255,255,0.3); /* Internet Explorer uses this value as the progress bar's value colour */
    }
    .controls progress[data-state="fake"] {
        display: none;
    }
    .controls progress::-webkit-progress-bar {
        background-color: rgba(255,255,255,0.3);
    }

    .controls progress::-moz-progress-bar {
        background-color: rgba(255,255,255,0.3);
    }
    /* Chrome requires its own rule for this, otherwise it ignores it */
    .controls progress::-webkit-progress-value {
        background-color: rgba(255,255,255,0.3);
    }

    .js [hidden] { display: none; }

    @media print {
        [hidden] { display: block; }
    }


}


@layer components {
    .button {
        @apply bg-white antialiased font-light uppercase tracking-wide leading-tight;
        cursor: pointer;
        padding: .5em .8em .4em;
        font-size: .9rem;
        /* margin-top: -1px; removed 26/05/2020 */
        position: relative;
        white-space: nowrap;
    }
    .button-large {
        @apply text-base;
    }
    .button-xl {
        @apply text-lg px-6 py-4;
    }
    .button-sm {
        @apply button text-xs px-2 py-1;
    }
    .button-xs {
        @apply button text-xs px-1 py-px;
    }
    .button-sm-trans {
        @apply button text-xs px-2 py-1 bg-transparent;
    }

    .button:hover {
      @apply text-white bg-slate;
    }
    .button:active {
        top: 1px;
    }
    .button[disabled] {
        @apply bg-white text-slate cursor-default;
    }
    .button[disabled]:hover {
        @apply bg-white text-slate cursor-default;
    }
    #cart-proceed[disabled] {
        @apply bg-white cursor-default opacity-70;
    }
    #cart-proceed[disabled]:hover {
        @apply bg-white border-slate-lightest text-slate cursor-default;
    }
    .button-dark {
        @apply text-white bg-slate;
    }
    .button-dark:hover {
        @apply bg-slate-dark;
    }

    .home.js-nav-open #header {
        @apply bg-plaster-light text-slate-dark;
    }

    @screen maxmd {
        .home.js-home-scrolled #header {
            @apply bg-plaster-light text-slate-dark;
        }
    }

    .sale-label {
        @apply bg-white rounded leading-none px-1 py-0.5 relative transform scale-[0.85] origin-left inline-block;
    }
    /* .sale-label:after {
        content: "";
        position: absolute;
        right: -.5em;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: .7em solid red;
        border-top: .7em solid transparent;
        border-bottom: .7em solid transparent;
    } */

}


@layer utilities {
    .debug {
        outline: 1px solid red;
        & > * {
            outline: 1px solid green;
        }
    }
    .aspect-auto { aspect-ratio: auto; }
    .aspect-square { aspect-ratio: 1 / 1; }
    .aspect-video { aspect-ratio: 16 / 9; }

    .underline-links a {
        text-decoration: underline;
    }

    /* purgecss start ignore */
    .author-text {
        @apply antialiased;

        & > * + *, & li + li, & li > p + p {
            @apply mt-4;
        }
        & li + ul
        & strong {
            @apply font-normal;
        }
        & a {
            text-decoration: underline;
        }
        & h1, & h2, & h3, & h4, & h5 { 
            @apply font-thin tracking-wide uppercase mt-8;
        }
        & h5 { @apply font-light; }
        & h1 { @apply text-2xl; }
        & h2 { @apply text-xl; }
        & h3 { @apply text-lg; }
        & h4 { @apply text-base; }
        & h5 { @apply text-sm; }

        & blockquote {
            @apply border-l-4 border-slate-light pl-4 italic;
        }
        & ul, & ol {
            @apply pl-5;
            @apply list-disc;
        }
        & ol {
            @apply list-decimal;
        }
    }

    .author-text-simple {
        & a { text-decoration: underline; }
        & p { @apply mb-2; @apply leading-tight; }
    }
    /* purgecss end ignore */

    @keyframes fadeIn { 
        from { opacity: 0; } 
        to { opacity: 1; } 
    }

    .fade-in {
      opacity: 0;
      animation: fadeIn ease-in 1;
      animation-fill-mode: forwards;
      animation-duration: .25s;
      @media print { opacity: 1 !important; }
    }
    .fade-in-one {
      animation-delay: .6s;
    }
    .fade-in-two {
      animation-delay: 1.2s;
    }

    @supports(mix-blend-mode: multiply) {
        .multiply-overlay::before { 
            content: '';
            @apply absolute inset-0 bg-slate-light;
            mix-blend-mode: multiply; 
            opacity: 1;
        }
        .multiply-overlay:hover::before {
            opacity: .8;
        }
        .multiply-overlay.dark::before {
            @apply bg-plaster;
        }
    }

    .transition-color { transition: color .1s; }


    .columns-2 {
      -webkit-columns: 2 18rem;
         -moz-columns: 2 18rem;
              columns: 2 18rem;
    }

    .columns-3 {
      -webkit-columns: 3 9rem;
         -moz-columns: 3 9rem;
              columns: 3 9rem;
    }

    .avoid-break {
        -webkit-column-break-inside: avoid;
              page-break-inside: avoid;
                   break-inside: avoid;
    }



    .icon {
        vertical-align: -13%;
        margin-right: .25rem;
    }
    .align-bottom-24 {
        vertical-align: -24%;
    }
    .align-b-17 {
        vertical-align: -17%;
    }


    .scroll-snap-x {
        scroll-snap-type: x mandatory;
        scroll-padding: 20%;
    }
    .scroll-snap-align-start {
        scroll-snap-align: start;
    }
    .scroll-snap-align-center {
        scroll-snap-align: center;
    }

    .overflow-scrolling-touch {
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .overflow-scrolling-touch::-webkit-scrollbar {
        display: none;
    }

    .bottom-1\/2 {
        bottom: 50%;
    }
    .h-1\/2 {
        height: 50%;
    }
    .h-2\/3 {
        height: 66.6667%;
    }
    .h-3\/4 {
        height: 75%;
    }

}




@keyframes col {
    0% { fill: inherit }
    50% { fill: green }
    100% { fill: inherit }
}
@keyframes opa {
    0% { opacity: 1 }
    50% { opacity: 0.65 }
    100% { opacity: 1 }
}

.logo-full .dots { opacity: 0; }
.logo-full .dots-1 { animation: fadeIn .75s ease-in 1s forwards, opa 1.5s ease-in-out 1.75s infinite; }
.logo-full .dots-2 { animation: fadeIn .5s ease-in 1.25s forwards, opa 1.2s linear 1.75s infinite; }
.logo-full .dots-3 { animation: fadeIn .5s ease-in 1.5s forwards, opa 1s linear 2s infinite; }
.logo-full .dots-4 { fill: theme('colors.plaster.dark'); animation: fadeIn .75s ease-in .5s forwards, opa 2s ease-in-out 1.25s infinite; }
.logo-full .dots-5 { fill: theme('colors.stone.light'); animation: fadeIn .75s ease-in 0s forwards, opa 1.7s linear .75s infinite; }




nav a:hover, nav form:hover { @apply text-slate-darkest; }
nav a.active { @apply font-medium; }
nav.dark a:hover, nav.dark form:hover { @apply text-plaster; }
.js-nav-open nav.homepage a:hover, 
.js-nav-open nav.homepage form:hover { @apply text-slate-darkest; }
.js-nav-open .notice { @apply text-slate; }

.js-nav-open #header {
    @apply overflow-x-scroll h-screen;
}
@screen xxs {
    .js-nav-open #header {
        @apply overflow-visible h-auto;
    }
}


@screen maxmd {
    .js-home-scrolled nav.homepage a:hover, 
    .js-home-scrolled nav.homepage form:hover { @apply text-slate-darkest; }
    .js-home-scrolled .notice { @apply text-slate; }
}

.site-nav a,
.site-nav input[type="search"], 
.site-nav input[type="search"]:hover {
    /* color: theme('colors.slate.light'); */
    color: currentColor;
}
.site-nav input[type="search"]::placeholder,
.site-nav input[type="search"]:hover::placeholder {
    color: currentColor;
}
.site-nav input[type="search"]::-ms-input-placeholder {
    color: currentColor;
}
.site-nav input[type="search"]:hover::-ms-input-placeholder {
    color: currentColor;
}
.site-nav input[type="search"]::-webkit-search-cancel-button {
    color: currentColor;
}
.site-nav input[type="search"]:focus {
    border-bottom-color: transparent;
}

/* .site-nav.dark a, 
.site-nav.dark input[type="search"] { color: theme('colors.plaster.dark'); }

.site-nav.dark a:hover,
.site-nav.dark form:hover,
.site-nav.dark input[type="search"]:hover,
.site-nav.dark input[type="search"]:hover::placeholder,
.site-nav.dark input[type="search"]::-webkit-search-cancel-button {
    color: theme('colors.plaster.DEFAULT');
} */




@screen maxmd {
    
    .site-nav ul.nav-items:not([hidden]) {
        @apply flex absolute right-0 left-0;
        bottom: -3.9rem;
    }
    .site-nav.stockist ul.nav-items:not([hidden]) {
        bottom: -5.5rem;
    }
    /* .site-nav {
        transition: margin 100ms;
    } */
    /* .js-open .site-nav {
        margin-bottom: 3rem;
    } */
}

@screen md {
    .site-nav ul.nav-items { @apply flex }
}


.site-nav a.js-nav-open { @apply relative }
.site-nav a.js-nav-open::after { 
    content: "^";
    @apply block transform rotate-180 absolute bottom-1.5 left-0 right-0 font-light text-center p-0 leading-none;
}

.site-nav button svg {
    display: block;
    pointer-events: none;
    stroke: currentcolor;
    vertical-align: middle;
    height: 26px;
    width: 26px;
}
.site-nav button path {
    transition: .1s all ease-in-out;
    transform-origin: top left;
    transform: rotate(0deg) translateY(0px) translateX(0px);
}
.site-nav button:hover path:nth-of-type(1) {
    transform: rotate(0deg) translateY(-1px) translateX(0px);
}
.site-nav button:hover path:nth-of-type(3) {
    transform: rotate(0deg) translateY(1px) translateX(0px);
}
.site-nav button[aria\-expanded=true] path:nth-of-type(1) {
    transform: rotate(-45deg) translateY(15px) translateX(-16px);
}
.site-nav button[aria\-expanded=true] path:nth-of-type(2) {
    opacity: 0;
}
.site-nav button[aria\-expanded=true] path:nth-of-type(3) {
    transform: rotate(45deg) translateY(-24px) translateX(7px);
}

/* .site-nav .cart .icon {
    margin-right: .1rem;
}
.site-nav .cart .badge, .selection .badge {
    @apply text-xs font-num inline-block;
    vertical-align: 0%;
}
.selection .icon { vertical-align: -20%; } */

.site-nav.mini {
    @apply w-full inline-flex flex-col justify-center items-center mt-24 mx-auto text-xs;
    /* & * { @apply debug; } */
    & ul.nav-items { @apply ml-0; }
    & ul.nav-items {
        position: static;
        @apply flex flex-nowrap;
    }
    & ul.nav-ui {
        @apply justify-center w-auto;
    }

    & .searchfield { @apply w-24; }
    & ul.nav-items:after, & ul:before {
        content: '';
        width: .75rem;
    }
    & ul.nav-ui:before {
        content: '';
        width: 1.75rem;
    }
}

@screen sm {
    .site-nav.mini { 
        @apply flex-row mt-32;
    }
    .site-nav.mini ul.nav-items:after, 
    .site-nav.mini ul:before {
        /* width: 0; */
    }
    .site-nav.mini ul.nav-items:after {
        width: .25rem;
    }
    .site-nav.mini ul.nav-ui:before {
        width: .75rem;
    }
}



.shop-nav-section button::before {
    content: "+";
    @apply border border-plaster-dark/30 text-center inline-block;
    line-height: 1; width: 1rem; height: 1rem;
    padding-bottom: 0.9rem; padding-left: .04rem;
    vertical-align: -2%;
    @apply mr-1.5;
}
.shop-nav-section button[aria\-expanded=true]::before { 
    content: "\2212";
}
@media print {
    .shop-nav-section, .shop-nav-section button { @apply text-slate-dark; }
    .shop-nav-section button::before { display: none; }
}

.expandable-section {
    /* display: flex;
    transition: max-height .2s ease-in-out;
    height: auto;
    max-height: 500px;
    overflow: hidden; */
}
.expandable-section[hidden] {
    /* display: flex;
    max-height: 0; */
}
@screen md {
    .expandable-section[hidden] {
        display: flex; /* override main hidden defaults */
        /* max-height: 500px; */
    }
}


.home-poster.darken::before {
    content: '';
    /* background: linear-gradient(150deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 65%),
                linear-gradient(150deg, rgba(0,0,0,0) 60%, rgba(0,0,0,.6) 85%); */
    background: linear-gradient(200deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 45%);
    mix-blend-mode: multiply;
    @apply absolute inset-0 w-full h-full z-10;
}

#main {
    min-height: 30em;
}


input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea {
    @apply px-3 py-2;
    &:focus { 
        outline: 1px solid theme('colors.slate.light'); 
        outline-offset: 0px;
    }

    /* &::placeholder { @apply text-red; } */
}


input[type="search"] {
    -webkit-appearance: none;
    border: 0;
    color: theme('colors.slate.dark');
    outline-offset: 0;
    border-bottom: 2px solid transparent;
    border-top: 1px solid transparent;
}
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
input[type="search"]:focus { 
    outline: 0; border-bottom-color: theme('colors.slate.light');
}
input[type="search"]::placeholder-shown {  }
input[type="search"]::placeholder { 
    color: currentColor; 
    text-transform: uppercase;
    @apply tracking-wider;
    opacity: 1;
}
input[type="search"]:focus::placeholder {
    opacity: 0;
    color: theme('colors.slate.darkest');
}
input[type="search"]:hover::placeholder {
    color: theme('colors.slate.darkest');
}


select {
    @apply bg-white;
    appearance: none;
    border-radius: 0;
    border: 0;
    @apply font-light;
    @apply px-3 py-2;
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='25px' height='25px' viewBox='0 0 25 12' version='1.1' xmlns='http://www.w3.org/2000/svg' fill='none' stroke-width='0.75' stroke='#5D5864'%3E%3Cpolyline points='2 4 8 9 14 4'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
}
select:hover {
  @apply text-white;
  @apply bg-slate;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='25px' height='25px' viewBox='0 0 25 12' version='1.1' xmlns='http://www.w3.org/2000/svg' fill='none' stroke-width='0.75' stroke='#fff'%3E%3Cpolyline points='2 4 8 9 14 4'%3E%3C/polyline%3E%3C/svg%3E");
}
select.nostock {
    @apply opacity-50;
    &:hover { 
        @apply bg-white text-slate; 
        background-image: url("data:image/svg+xml,%0A%3Csvg width='25px' height='25px' viewBox='0 0 25 12' version='1.1' xmlns='http://www.w3.org/2000/svg' fill='none' stroke-width='0.75' stroke='#5D5864'%3E%3Cpolyline points='2 4 8 9 14 4'%3E%3C/polyline%3E%3C/svg%3E");
    }
}
select.small-with-button {
    margin-top: -1px;
    font-size: .9rem;
    padding: .5em 30px .4em .8em;
}
select.small {
    font-size: .9rem;
    padding: .5em 30px .4em .8em;
}
select::-ms-expand {
    display: none;
}


label {
    @apply block mb-1 mt-3;
}
label.has-error {
    @apply text-red;
}

label input[type="checkbox"] {
    @apply relative mr-2;
}
label.has-error + input { 
    @apply border border-red-light;
}

fieldset {
    @apply mb-6 p-0 border-0;
}


.lookbook-item figcaption {
    text-shadow: 1px 1px 2px rgba(0,0,0,.2), 0 0 15px rgba(0,0,0,1);
    transition: opacity .1s ease-in-out;
    opacity: 0;
    mix-blend-mode: screen;
}
.lookbook-item figcaption svg {
    display: inline;
}
.lookbook-item:hover figcaption {
    opacity: 1;
}
.touch .lookbook-item figcaption {
    opacity: 1;
}



.lookbook-imgblock, 
.lookbook-textblock,
.lookbook-group {
    @apply w-full;
}
.lookbook-group {
    & .lookbook-imgblock,
    & .lookbook-textblock { 
        @apply w-full;
    }
}
.lookbook-textblock {
    min-height: 250px;
    @apply text-center flex justify-center items-center;
    & blockquote {
        @apply px-6 py-8;
        /* @apply mx-auto; */
    }
}
.lookbook-imgblock figcaption {
    text-shadow: 1px 1px 2px rgba(0,0,0,.2), 0 0 15px rgba(0,0,0,1);
    transition: opacity .1s ease-in-out;
    opacity: 0;
    mix-blend-mode: screen;
}
.lookbook-imgblock figcaption svg {
    display: inline;
}
.lookbook-imgblock:hover figcaption {
    opacity: 1;
}

/* purgecss ignore */
.touch .lookbook-imgblock figcaption {
    opacity: 1;
}
.lookbook-textblock.empty {
    display: none;
}
.lookbook-blocks::after {
    content: ""; flex: auto;
    /* @apply bg-slate-dark; */
}

@screen sm {
    .lookbook-imgblock, 
    .lookbook-textblock {
        @apply w-1/2; 
    }
    .lookbook-group {
        @apply w-full flex;
        & .lookbook-textblock { @apply h-full; }
    }
    .lookbook-imgblock.large {
        @apply w-full;
    }
    .lookbook-textblock {
        & blockquote {
            max-width: 380px;
        }
    }
    .lookbook-textblock.empty {
        display: block;
    }

/*     .lookbook-group.start {
        display: contents;

        & .lookbook-imgblock,
        & .lookbook-textblock { 
            @apply w-1/2;
        }
        & .lookbook-textblock { @apply h-full; }
    } */

}

@screen lg {
    .lookbook-imgblock, 
    .lookbook-textblock,
    .lookbook-group {
        @apply w-1/3;
    }
    .lookbook-group {
        @apply flex-col;

        & .lookbook-imgblock,
        & .lookbook-textblock { 
            flex: 0 0 auto; /* fuck you IE */
        }
    }
    .lookbook-imgblock.large {
        @apply w-2/3;
    }
    .lookbook-group.start {
        display: flex;

        & .lookbook-imgblock,
        & .lookbook-textblock { 
            @apply w-full;
        }
    }
}



.story-block {
    @apply w-full;
    & div { 
        @apply mx-auto; 
    }
    & img {
        @apply mx-auto;
    }
}
.story-block-text { 
    @apply relative font-serif text-left flex justify-center items-center text-2xl leading-tight;
    background-size: cover;
    background-position: 50% 100%;
    & div {
        @apply px-12 py-20 mx-auto max-w-2xl w-full;
        z-index: 2;
    }
}
.story-block-text.background {
    @apply text-white subpixel-antialiased;
}
.story-block-text.background::before {
    content: '';
    position: absolute;
    @apply inset-0;
    background: rgba(255,255,255,.25);
}
.story-block-text.empty { display: none; }
.story-block-text div {
    @apply bg-transparent;
}


@screen sm {
    .story-block {
        @apply w-1/2;
    }
    .story-block.single {
        @apply w-full; 
    }
    .story-block.double { 
        @apply w-1/2;
        & div {
            @apply w-full;
        }
    }
    .story-block.multiple {
        @apply w-full flex;
    }

    .story-block-text {
        @apply w-full text-2xl;
        & div {
            @apply p-6 py-16;
        }
    }
}


@media ( min-width: 860px ) {
    .story-block-text {
        font-size: 2.8vw;
    }
}

@screen lg {
    .story-block {
        @apply w-1/3;
    }
    .story-block.double { 
        @apply w-1/2;
        & div {
            @apply w-8/12;
        }
    }
    .story-block.multiple {
        @apply w-1/3 block;
    }
    .story-block-text {
        @apply w-2/3 text-2xl p-12;
        min-height: 0;
        font-size: 2.3vw;
        & div {
            @apply py-0 px-6 max-w-full;
        }
    }
    .story-block-text.empty { @apply flex; }
}

@screen xl {
    .story-block-text {
        @apply text-3xl;
    }
}



.product-material, 
.product-dimensions,
.product-handmade {
    @apply text-slate text-sm leading-tight font-light mb-2;
}
.product-material, 
.product-dimensions {
    @apply text-xs uppercase tracking-wide; 
}
.product-dimensions { 
    /* @apply mb-12; */
}
.product-handmade {
    @apply mt-6;
}



.product-extra {
    @apply font-light antialiased text-slate mb-4;
}
.product-extra button {
    @apply uppercase text-xs tracking-wide font-light antialiased text-slate;
}
.product-extra button::before {
    content: "+";
    @apply border border-slate-light text-center inline-block bg-white;
    line-height: 1; width: .9rem; height: .9rem;
    padding-bottom: .04rem;
    padding-left: .04rem;
    vertical-align: 5%;
    @apply mr-1;
}
.product-extra button[aria\-expanded=true]::before { 
    content: "–";
}
.expandable-info {
    @apply py-3;
}
@media print {
    .product-extra, .product-extra button { @apply text-slate-dark; }
    .product-extra button::before { display: none; }
}


.cart-contents, .cart-details {
    flex: 1 1 100%;
}
.cart-summary {
    flex: 0 0 30%;
    text-align: left;
}
.cart-item-image {
    flex: 1 1 100%;
    & img { min-width: 100px; }
}
.cart-item-details {
    flex: 1 1 50%;
    line-height: 1.1;
}
.cart-item-quantity {
    flex: 1 1 100%;
}
.cart-item-price {
    flex: 1 1 100%;
}

@screen xs {
    .cart-item-image {
        flex: 1 1 15%;
    }
}

@screen sm {
    .cart-item-quantity {
        flex: 1 1 100%;
    }
}

@screen md {
    .cart-item-image {
        flex: 0 0 150px;
    }
    .cart-item-details {
        flex: 1 1 auto;
    }
    .cart-item-quantity {
        flex: 0 0 60px;
    }
    .cart-item-price {
        flex: 0 0 200px;
    }
}



.address-data {
    @apply leading-tight;
    & li { @apply mb-1; }
}

/* purgecss start ignore */

select.has-error {
    border: 1px solid theme('colors.red.DEFAULT');
}
.has-error input {
    border: 1px solid theme('colors.red.DEFAULT');
}
.has-error .help-block {
    color: theme('colors.red.DEFAULT');
}

.flash {
    @apply block text-xs text-red leading-tight mt-1;
}

.stripe-payment-intents-form {
    @apply w-full my-3;
    
    & .card-holder-name {
        outline-offset: 0px;
    }
    & .StripeElement {
        @apply bg-white px-3 py-3 pr-0;
        outline-offset: 0px;
    }
    & .StripeElement--focus {
        outline: 1px solid theme('colors.slate.light'); 
    }
    & .StripeElement--invalid {
        outline: 1px solid theme('colors.red.DEFAULT'); 
    }
    & .StripeElement--complete, & .card-holder-name:valid {
        outline: 1px solid theme('colors.green'); 
    }
}

@media print {
    .navigation, .cookies {
        display: none;
    }
}   



@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  svg.logo-simple { height: 20px; }
  svg.logo-footer { height: 88px; }
}
/* purgecss end ignore */